import { useCallback } from 'react';
import { useSelectedListId } from '@module/list/store/useListStore';
import { PaginationFilters } from '@module/shared/hooks/useTableFilters';
import { useLocation } from 'react-router-dom';

export const ROUTES = {
  LIST: {
    OVERVIEW: '/:listId?/list/overview',
    IMPORT: '/:listId?/list/import',
    EXPORT: '/:listId?/list/export',
    SEGMENTS: '/:listId?/list/segments',
    NEW_SEGMENTS: '/:listId?/list/segments/new',
    EDIT_SEGMENT: '/:listId?/list/segments/:id',
    CONTACTS_MANAGER: '/:listId?/list/contacts-manager',
    NEW_CONTACT_SEARCH: '/:listId?/list/contacts-manager/new',
    CONTACT_SEARCH_REPORT: '/:listId?/list/contacts-manager/report/:id',
    EDIT_CONVERSION_POINT: '/:listId?/list/conversion-points/:id',
    NEW_CONVERSION_POINTS: '/:listId?/list/conversion-points/new',
    AUTOMATION_RULES: '/:listId?/list/automation-rules',
    SETTINGS: '/:listId?/list/settings',
    NEW_FIELD: '/:listId?/list/settings/new',
  },
  CAMPAIGNS: {
    OVERVIEW: '/:listId?/campaigns/overview',
    A_B: '/:listId?/campaigns/a-b',
    EVENTS_AND_TRIGGERS: '/:listId?/campaigns/events-and-triggers',
    TRANSACTIONAL: '/:listId?/campaigns/transactional',
    CALENDAR: '/:listId?/campaigns/calendar',
  },
  AUTOMATION: {
    LIST: '/:listId?/automation/list',
  },
  CONTENT: {
    TEMPLATES: '/:listId?/content/templates',
    IMAGES: '/:listId?/content/images',
    EMAILS: '/:listId?/content/emails',
    EMAIL_FORM: '/:listId?/content/emails/:id',
    SMS: '/:listId?/content/sms',
  },
  ANALYTICS: {
    INSIGHTS: '/:listId?/analytics/insights',
    OVERVIEW: '/:listId?/analytics/overview',
    AGGREGATE: '/:listId?/analytics/aggregate',
    MATRIX: '/:listId?/analytics/matrix',
    A_B_TEST: '/:listId?/analytics/a-b-test',
    EVENTS: '/:listId?/analytics/events',
    TRANSACTIONAL: '/:listId?/analytics/transactional',
    AUTOMATION: '/:listId?/analytics/automation',
    ANALYTICS_BY_WORKFLOW: '/:listId?/automation/:workflowId',
  },
  REPORTS: {
    CONTACTS_ACTIVITY: '/:listId?/reports/contacts-activity',
    AGGREGATE_REPORT: '/:listId?/reports/ag-report',
  },
  TOOLS: {
    AI: '/:listId?/tools/ai',
    VALIDATION: '/:listId?/tools/validation',
    VALIDATION_FORM: '/:listId?/tools/validation/form/:id?',
    FORM_BUILDER: '/:listId?/tools/form-builder',
    NEW_FORM_BUILDER: '/:listId?/tools/form-builder/new',
    EDIT_FORM_BUILDER: '/:listId?/tools/form-builder/:id',
    COPY_FORM_BUILDER: '/:listId?/tools/form-builder/:id/copy',
    CONVERSION_POINTS: '/:listId?/tools/conversion-points',
  },
  VENDORS: {
    CONNECTIONS: '/:listId?/vendors/connections',
    ESP_AND_SMTP: '/:listId?/vendors/esp-and-smtp',
    SMS: '/:listId?/vendors/sms',
    EDIT_CONNECTION: '/:listId?/vendors/edit-connection/:id',
    EVENTS_FORM: '/:listId?/events/form/:id',
    AUTOMATION_RULES_FORM: '/:listId?/automation_rules/form/:id',
  },
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    REGISTER: '/auth/register',
    FORGOT_PASSWORD: '/auth/forgot-password',
    FORGOT_PASSWORD_SENT_LINK: '/auth/forgot-password/sent-link/:email',
    FORGOT_PASSWORD_RESET: '/auth/forgot-password/reset',
    FORGOT_PASSWORD_RESET_COMPLETE: '/auth/forgot-password/pwd-reset-complete',
    FIRST_LOGIN: '/auth/first-login',
  },
  SETTINGS: {
    PROFILE: '/:listId?/settings/profile',
    USAGE: '/:listId?/settings/usage',
    DOMAIN_ROUTING: '/:listId?/settings/domain-routing',
    CONFIGURATIONS: '/:listId?/settings/configurations',
    TRACKING_IMAGE_DOMAINS: '/:listId?/settings/tracking-image-domains',
    USERS: '/:listId?/settings/users',
    LISTS: '/:listId?/settings/lists',
  },
  NOP: {
    INDEX: '/:listId?/nop',
  },
  SETUP: {
    WELCOME: '/:listId?/setup/welcome',
  },
  PRICING: {
    INDEX: '/:listId?/pricing',
  },
} as const;

export const useTransformCurrentPathUsingList = () => {
  const location = useLocation();
  const selectedListId = useSelectedListId();

  return useCallback(
    (listId: number | 'all') => {
      const paginationFilterKeys: { [key in NonNullable<keyof PaginationFilters>]: undefined } = {
        search: undefined,
        page: undefined,
        limit: undefined,
        order: undefined,
        sort: undefined,
        offset: undefined,
      };

      const searchParams = new URLSearchParams(location.search);
      Object.keys(paginationFilterKeys).forEach((k) => {
        if (searchParams.has(k)) {
          searchParams.delete(k);
        }
      });

      const search = searchParams.toString() ? `?${searchParams.toString()}` : '';
      if (selectedListId && location.pathname.includes(selectedListId.toString())) {
        return `${location.pathname.replace(selectedListId.toString(), listId.toString())}${search}`;
      }

      return `/${listId.toString()}${location.pathname}${search}`;
    },
    [location, selectedListId],
  );
};

// Utility type to extract all possible paths
type ExtractRoutes<T> = T extends string
  ? T
  : T extends Record<string, unknown>
    ? { [K in keyof T]: ExtractRoutes<T[K]> }[keyof T]
    : never;

// Infer all routes from the `Routes` object
type AllRoutes = ExtractRoutes<typeof ROUTES>;

// Define the type for `PAGE_TITLE`
type PageTitleType = Record<AllRoutes, string>;
export const PAGE_TITLE: PageTitleType = {
  [ROUTES.LIST.OVERVIEW]: 'List Overview',
  [ROUTES.LIST.IMPORT]: 'Import',
  [ROUTES.LIST.EXPORT]: 'Export',
  [ROUTES.LIST.SEGMENTS]: 'Segments',
  [ROUTES.LIST.NEW_SEGMENTS]: 'New Segment',
  [ROUTES.LIST.EDIT_SEGMENT]: 'Edit Segment',
  [ROUTES.LIST.CONTACTS_MANAGER]: 'Contacts Manager',
  [ROUTES.LIST.NEW_CONTACT_SEARCH]: 'New Contact Search',
  [ROUTES.LIST.CONTACT_SEARCH_REPORT]: 'Contact Search Report',
  [ROUTES.LIST.EDIT_CONVERSION_POINT]: 'Edit Conversion Point',
  [ROUTES.LIST.NEW_CONVERSION_POINTS]: 'New Conversion Points',
  [ROUTES.LIST.AUTOMATION_RULES]: 'Automation Rules',
  [ROUTES.LIST.SETTINGS]: 'List Settings',
  [ROUTES.LIST.NEW_FIELD]: 'New Field',
  [ROUTES.CAMPAIGNS.OVERVIEW]: 'Campaigns Overview',
  [ROUTES.CAMPAIGNS.A_B]: 'A/B Campaigns',
  [ROUTES.CAMPAIGNS.EVENTS_AND_TRIGGERS]: 'Events & Triggers',
  [ROUTES.CAMPAIGNS.TRANSACTIONAL]: 'Transactional Campaigns',
  [ROUTES.CAMPAIGNS.CALENDAR]: 'Calendar',
  [ROUTES.AUTOMATION.LIST]: 'Automation',
  [ROUTES.CONTENT.TEMPLATES]: 'Templates Library',
  [ROUTES.CONTENT.IMAGES]: 'Image Library',
  [ROUTES.CONTENT.EMAILS]: 'Email Messages',
  [ROUTES.CONTENT.EMAIL_FORM]: 'Email Form',
  [ROUTES.CONTENT.SMS]: 'SMS Messages',
  [ROUTES.ANALYTICS.INSIGHTS]: 'Insights',
  [ROUTES.ANALYTICS.OVERVIEW]: 'Analytics Overview',
  [ROUTES.ANALYTICS.AGGREGATE]: 'Aggregate',
  [ROUTES.ANALYTICS.MATRIX]: 'Matrix',
  [ROUTES.ANALYTICS.A_B_TEST]: 'A/B Test',
  [ROUTES.ANALYTICS.EVENTS]: 'Events',
  [ROUTES.ANALYTICS.TRANSACTIONAL]: 'Transactional',
  [ROUTES.ANALYTICS.AUTOMATION]: 'Automation Analytics', // https://ongage.atlassian.net/browse/RND-4141?focusedCommentId=27293
  [ROUTES.ANALYTICS.ANALYTICS_BY_WORKFLOW]: 'Automation Analytics by Workflow',
  [ROUTES.REPORTS.CONTACTS_ACTIVITY]: 'Contacts Activity',
  [ROUTES.REPORTS.AGGREGATE_REPORT]: 'Custom Aggregate Reports',
  [ROUTES.TOOLS.AI]: 'AI',
  [ROUTES.TOOLS.VALIDATION]: 'Email Validation',
  [ROUTES.TOOLS.VALIDATION_FORM]: 'Validation Form',
  [ROUTES.TOOLS.FORM_BUILDER]: 'Form Builder',
  [ROUTES.TOOLS.NEW_FORM_BUILDER]: 'New Form Builder',
  [ROUTES.TOOLS.EDIT_FORM_BUILDER]: 'Edit Form Builder',
  [ROUTES.TOOLS.COPY_FORM_BUILDER]: 'Copy Form Builder',
  [ROUTES.TOOLS.CONVERSION_POINTS]: 'Conversion Points',
  [ROUTES.VENDORS.CONNECTIONS]: 'My Connections',
  [ROUTES.VENDORS.ESP_AND_SMTP]: 'ESP/SMTP Vendors',
  [ROUTES.VENDORS.SMS]: 'SMS Vendors',
  [ROUTES.VENDORS.EDIT_CONNECTION]: 'Edit Connection',
  [ROUTES.VENDORS.EVENTS_FORM]: 'Events Form',
  [ROUTES.VENDORS.AUTOMATION_RULES_FORM]: 'Automation Rules Form',
  [ROUTES.AUTH.LOGIN]: 'Log in | Ongage',
  [ROUTES.AUTH.LOGOUT]: 'Logout',
  [ROUTES.AUTH.REGISTER]: 'Register',
  [ROUTES.AUTH.FORGOT_PASSWORD]: 'Forgot Password',
  [ROUTES.AUTH.FORGOT_PASSWORD_SENT_LINK]: 'Forgot Password Sent Link',
  [ROUTES.AUTH.FORGOT_PASSWORD_RESET]: 'Forgot Password Reset',
  [ROUTES.AUTH.FORGOT_PASSWORD_RESET_COMPLETE]: 'Forgot Password Reset Complete',
  [ROUTES.AUTH.FIRST_LOGIN]: 'First Login',
  [ROUTES.SETTINGS.PROFILE]: 'Profile',
  [ROUTES.SETTINGS.USAGE]: 'Usage',
  [ROUTES.SETTINGS.DOMAIN_ROUTING]: 'SMTP Routing',
  [ROUTES.SETTINGS.CONFIGURATIONS]: 'Configurations',
  [ROUTES.SETTINGS.TRACKING_IMAGE_DOMAINS]: 'Tracking Image & Domains',
  [ROUTES.SETTINGS.USERS]: 'Users',
  [ROUTES.SETTINGS.LISTS]: 'Lists',
  [ROUTES.NOP.INDEX]: 'Nop',
  [ROUTES.SETUP.WELCOME]: 'Welcome',
  [ROUTES.PRICING.INDEX]: 'Pricing',
};
