import { FC, forwardRef, PropsWithChildren } from 'react';
import { ICONS } from '@styles/icons';
import clsx from 'clsx';
import { Col, Dropdown, DropdownToggleProps } from 'react-bootstrap';
import { DropdownProps } from 'react-bootstrap/Dropdown';

import styles from './Filters.module.scss';
import { Icon } from '../Icon/Icon';

type Props = {
  options: Array<{
    label: string;
    icon?: keyof typeof ICONS;
    onClick: () => void;
    disabled?: boolean;
  }>;
};
export const FiltersDropdown: FC<Props> = ({ options }) => {
  return (
    <Dropdown drop="down-centered" as={FiltersToggle}>
      <Dropdown.Toggle as={FiltersText} />
      <Dropdown.Menu className={clsx('mt-4', styles.menu)}>
        {options.map((option, index) => (
          <Dropdown.Item
            key={index}
            onClick={option.onClick}
            disabled={option.disabled}
            className={clsx({ 'bg-secondary bg-opacity-25': option.disabled })}
          >
            <Col className="d-flex flex-row gap-2 px-2 py-1">
              {option.icon && <Icon name={option.icon} />}
              <span className="text-gray-700 fs-6 fw-bold">{option.label}</span>
            </Col>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const FiltersToggle = forwardRef<HTMLDivElement, PropsWithChildren<DropdownProps>>(
  ({ children, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(styles.filtersContainer, {
          [styles.show]: rest.className?.includes('show'),
        })}
      >
        {children}
      </div>
    );
  },
);
const FiltersText = forwardRef<HTMLSpanElement, DropdownToggleProps>((props, ref) => (
  <span ref={ref} {...props} className="d-flex align-items-center justify-content-center gap-2">
    <Icon name="filters" stroke="dark" />
    Filters
  </span>
));
