import { FC, useCallback, useEffect, useState } from 'react';
import { useAccessToken } from '@module/auth/store/authTokensStore';
import { useSelectedListId } from '@module/list/store/useListStore';
import { ActionType, useIframeMessage } from '@providers/IframeMessageProvider';
import clsx from 'clsx';

import config from '../../app-config';

interface IframePageProps {
  src:
    | string
    | (({ listId, accessToken }: { listId?: string | number; accessToken: string }) => string);
  isLegacyAppPage?: boolean;
}

const backgroundStyles = {
  width: '100%',
  minHeight: '100vh',
  background: 'url("/media/logos/default.svg") no-repeat center center',
};

export const IframePage: FC<IframePageProps> = ({ src, isLegacyAppPage = true }) => {
  const accessToken = useAccessToken();
  const listId = useSelectedListId();
  const iframeSrc =
    typeof src === 'string'
      ? constructDefaultIframeSrc({
          src,
          listId,
        })
      : src({ listId, accessToken });

  const [isLoading, setIsLoading] = useState(isLegacyAppPage);

  const { postMessage } = useIframeMessage();

  const onIframeLoad = useCallback(() => {
    const iframe = document.querySelector('iframe');
    if (iframe && iframe.contentWindow) {
      console.log(
        `[DEBUG] Iframe loaded, posting message with action: ${ActionType.ACCESS_TOKEN_INITIALIZE} and \n token: ${accessToken}`,
      );
      postMessage({
        action: ActionType.ACCESS_TOKEN_INITIALIZE,
        data: { access_token: accessToken },
        version: 1,
      });
    }
  }, [accessToken, postMessage]);

  const handleCompleteLoading = useCallback(
    (event: MessageEvent) => {
      if (event.data.action === ActionType.COMPLETE_LOGIN) {
        setIsLoading(false);
      }
    },
    [setIsLoading],
  );

  useEffect(() => {
    window.addEventListener('message', handleCompleteLoading);

    return () => {
      window.removeEventListener('message', handleCompleteLoading);
    };
  }, [handleCompleteLoading]);

  return (
    <>
      <div style={backgroundStyles} className={clsx({ ['d-none']: !isLoading })} />
      <iframe
        key={`${isLoading}-${src}`}
        className={clsx({ ['d-none']: isLoading })}
        src={iframeSrc}
        style={backgroundStyles}
        onLoad={onIframeLoad} // Attach onLoad event listener
      />
    </>
  );
};

function constructDefaultIframeSrc({
  src,
  listId,
}: {
  src: string;
  listId?: string | number;
}): string {
  const base = config.iframeUrl;
  const srcUrl = new URL(`/${listId ?? ''}${src}`, base);

  return srcUrl.toString();
}
