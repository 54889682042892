import React, { createContext, useCallback, useContext, useEffect } from 'react';
import {
  useAuthTokensActions,
  useRefreshToken,
  useRememberMe,
} from '@module/auth/store/authTokensStore';
import { useSelectedListId } from '@module/list/store/useListStore';
import { useWrappedTo } from '@routing/to';
import { useQueryClient } from '@tanstack/react-query';
import { ROUTES } from '@utils/routes';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';

import { refreshToken as refreshTokenRequest } from '../modules/auth/api';

export enum ActionType {
  ACCESS_TOKEN_INITIALIZE = 'access_token_initialize', // we send this msg to iframe without request from it
  ACCESS_TOKEN_REQUEST = 'access_token_request', // we receive this if iframe wants new token
  ACCESS_TOKEN_RENEWED = 'access_token_renewed', // we send this after we renew token after the iframe request
  FORCE_LOGIN_INITIALIZE = 'force_login_initialize', // we reply with this after we have 'force login data'
  LOCATION_CHANGED = 'location_changed', // we get this when iframe changes location
  COMPLETE_LOGIN = 'complete_login', // we get this when iframe successfully logs in
}

type VersionTypes = 1;

interface BaseMessage<T = unknown> {
  action: ActionType;
  data?: T;
  version: VersionTypes;
}

// send message types
interface AccessTokenInitMessage extends BaseMessage<{ access_token: string }> {
  action: ActionType.ACCESS_TOKEN_INITIALIZE;
}

interface AccessTokenRenewedMessage extends BaseMessage<{ access_token: string }> {
  action: ActionType.ACCESS_TOKEN_RENEWED;
}

interface ForceLoginInitializeMessage
  extends BaseMessage<{ access_token: string; refresh_token: string }> {
  action: ActionType.FORCE_LOGIN_INITIALIZE;
}

interface LocationChangedMessage extends BaseMessage<{ url: string; source: 'ma' | undefined }> {
  action: ActionType.LOCATION_CHANGED;
}

// receive message types
interface AccessTokenReqMessage extends BaseMessage {
  action: ActionType.ACCESS_TOKEN_REQUEST;
}

interface CompleteLoginMessage extends BaseMessage {
  action: ActionType.COMPLETE_LOGIN;
}

type IFrameMessage =
  | AccessTokenInitMessage
  | AccessTokenReqMessage
  | AccessTokenRenewedMessage
  | ForceLoginInitializeMessage
  | LocationChangedMessage
  | CompleteLoginMessage;

interface ContextType {
  postMessage: (msg: IFrameMessage) => void;
}

const IframeMessageContext = createContext<ContextType | undefined>(undefined);

export const IframeMessageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const refreshToken = useRefreshToken();
  const rememberMe = useRememberMe();
  const { setTokens, savePreviousTokens } = useAuthTokensActions();
  const posthog = usePostHog();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const to = useWrappedTo();

  const listId = useSelectedListId();

  const postMessage = useCallback((msg: IFrameMessage) => {
    const iframeWindow = document.querySelector('iframe')?.contentWindow;
    if (iframeWindow) {
      iframeWindow.postMessage(msg, '*');
    }
  }, []);

  const receiveMessageCallback = useCallback(
    (event: MessageEvent) => {
      const message = event.data as IFrameMessage;

      // Validate message structure
      if (message.action && message.version === 1) {
        // Handle the message based on action
        switch (message.action) {
          // this one we send when we receive request from iframe for the new token
          // We receive: ACCESS_TOKEN_RENEWED to renew the token
          // We send: message that the token is renewed ACCESS_TOKEN_RENEWED
          case ActionType.ACCESS_TOKEN_REQUEST: {
            refreshTokenRequest(refreshToken, rememberMe).then(({ data }) => {
              console.log(
                `[DEBUG] Received request for new refreshed token, sending message with action: ${ActionType.ACCESS_TOKEN_RENEWED}, \n token: ${data.access_token}`,
              );
              setTokens(data.access_token, data.refresh_token, posthog, rememberMe);
              postMessage({
                action: ActionType.ACCESS_TOKEN_RENEWED,
                data: { access_token: data.access_token },
                version: 1,
              });
            });
            break;
          }
          case ActionType.FORCE_LOGIN_INITIALIZE: {
            // in this case we don't repond with anything, just setting received tokens
            if (message.data) {
              savePreviousTokens();
              console.log(
                `[DEBUG] Force login initialise, received tokens: access ${message.data.access_token}, \n refresh ${message.data.refresh_token}`,
              );
              setTokens(message.data.access_token, message.data.refresh_token, posthog, rememberMe);
              queryClient.clear();
              navigate(to(ROUTES.CAMPAIGNS.OVERVIEW));
            }
            break;
          }
          case ActionType.LOCATION_CHANGED: {
            if (
              !message.data?.url ||
              message.data.url.includes('login') ||
              message.data.url.includes('logout')
            ) {
              return;
            }
            const url = new URL(message.data?.url);
            const source = message.data?.source;
            // Add source to route to strictly identify MA routes,to make IframeFallback work as expected
            const pathname = source ? `/${listId}/${source}${url.pathname}` : url.pathname;
            // update current URL without reloading of page=
            // history.replace was removed, so we have no other choice https://stackoverflow.com/a/77957079/10800367
            window.history.replaceState({}, '', pathname + url.search);
            break;
          }
          case ActionType.COMPLETE_LOGIN: {
            console.log(`[DEBUG] Received complete login message`);
            window.postMessage({ action: ActionType.COMPLETE_LOGIN });
            break;
          }
          default: {
            console.warn('Ongage app - unknown action', message.action);
            break;
          }
        }
      }
    },
    [
      refreshToken,
      rememberMe,
      setTokens,
      posthog,
      postMessage,
      savePreviousTokens,
      queryClient,
      navigate,
      to,
      listId,
    ],
  );

  const value: ContextType = {
    postMessage,
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessageCallback);

    console.log('set listeneer');
    return () => {
      window.removeEventListener('message', receiveMessageCallback);
    };
  }, [receiveMessageCallback]);

  return <IframeMessageContext.Provider value={value}>{children}</IframeMessageContext.Provider>;
};

export const useIframeMessage = () => {
  const context = useContext(IframeMessageContext);
  if (!context) {
    throw new Error('useIframeMessage must be used within an IframeMessageProvider');
  }
  return context;
};
