import { TableColumn, TableColumnWithAccessor } from '../ui';

type CsvColumn = {
  fld: string;
  name: string;
  type: string;
};

export const csvFileColumns = (columns: TableColumn<unknown>[]): CsvColumn[] => {
  return columns.filter(isExportableColumn).map((item) => ({
    fld: item.accessorField || '',
    name: item.exportColumnName,
    type: item.dataType || '',
  }));
};

const isExportableColumn = (
  column: TableColumn<unknown>,
): column is TableColumnWithAccessor<unknown> => 'exportColumnName' in column && !column.omit;
