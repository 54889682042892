import { useState, MouseEvent } from 'react';

export const usePasswordToggle = () => {
  const [visiblePasswords, setVisiblePasswords] = useState<string[]>([]);

  const togglePasswordVisibility = (event: MouseEvent) => {
    event.preventDefault();
    const id = event.currentTarget.id;
    setVisiblePasswords((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const isPasswordVisible = (id: string) => visiblePasswords.includes(id);

  return {
    isPasswordVisible,
    togglePasswordVisibility,
  };
};
