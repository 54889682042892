import { ComponentProps, FC, useCallback, useMemo } from 'react';
import { useListsV2 } from '@module/list/hooks/list-queries';
import { useExportUsersToCsv } from '@module/list/hooks/user-queries';
import { List } from '@module/list/store/models';
import { ListShowArchivedType } from '@module/settings/components/list-management/filters/ListTableFilters';
import { UserFilters } from '@module/shared/api/user-mgmt';
import { useSelectData } from '@module/shared/hooks/useSelectData';
import { useTableFilters } from '@module/shared/hooks/useTableFilters';
import { RefreshTableQueryButton } from '@module/shared/ui/common/RefreshTableQueryButton';
import { TableColumnHidingFilter } from '@module/shared/ui/filter/TableColumnHidingFilter';
import { CommonTableActions } from '@module/shared/ui/table/CommonTableActions';
import { DropDownItemAsRadioGroup } from '@ui/DropdownItem/DropdownItemAsRadioGroup';
import { DropDownItemAsSelect } from '@ui/DropdownItem/DropDownItemAsSelect';
import { FiltersDropdown } from '@ui/FilterDropdown/Filters';
import { SearchInput } from '@ui/filters';
import { FilterTagDropdown } from '@ui/FiltersTagDropdown/FiltersTagDropdown';
import { TableControlsContainer } from '@ui/table/controls/TableControlsContainer';

export const UsersFilters: FC = () => {
  const { filters, updateFilter } = useTableFilters<UserFilters>();

  const filtersDropdownOptions = useMemo<ComponentProps<typeof FiltersDropdown>['options']>(
    () => [
      {
        label: 'Role',
        onClick: () => updateFilter([{ key: 'role_id', value: RoleType.ALL }]),
        disabled: 'role_id' in filters,
      },
      {
        label: 'Status',
        onClick: () => updateFilter([{ key: 'status', value: StatusType.ACTIVE }]),
        disabled: 'status' in filters,
      },
      {
        label: 'List Access',
        onClick: () => updateFilter([{ key: 'allowed_lists_ids', value: 'all' }]),
        disabled: 'allowed_lists_ids' in filters,
      },
    ],
    [updateFilter, filters],
  );

  const exportQuery = useExportUsersToCsv();

  return (
    <div className="d-flex align-items-center justify-content-between mb-5 w-100">
      <UserFiltersGroup />
      <div className="d-flex align-items-center gap-4 justify-content-end">
        <FiltersDropdown options={filtersDropdownOptions} />
        <SearchInput />
        <TableControlsContainer>
          <RefreshTableQueryButton />
          <TableColumnHidingFilter />
          <CommonTableActions exportQuery={exportQuery} name={'Users list export'} />
        </TableControlsContainer>
      </div>
    </div>
  );
};

type UserFilterKeys = 'status' | 'role_id' | 'allowed_lists_ids';

export enum StatusType {
  ACTIVE = '0',
  DELETED = '1',
  ALL = 'all',
}

export enum RoleType {
  ALL = 'all',
  GENERAL_USER = 'is_general',
  ADMIN_USER = 'is_admin',
  CONTENT_WRITER = '4',
  EMAIL_MARKETER_NO_IMPORT_EXPORT = '5',
  REPORT_VIEWER = '6',
  GENERAL_USER_NO_EXPORT = '7',
  EMAIL_MARKETER_NO_LIST_SETTINGS = '8',
}

const HumanReadableStatusType = {
  [StatusType.ALL]: 'All',
  [StatusType.ACTIVE]: 'Active',
  [StatusType.DELETED]: 'Deleted',
};

const HumanReadableUserRoles = {
  [RoleType.ALL]: 'All',
  [RoleType.GENERAL_USER]: 'General User',
  [RoleType.ADMIN_USER]: 'Admin',
  [RoleType.CONTENT_WRITER]: 'Content Writer',
  [RoleType.EMAIL_MARKETER_NO_IMPORT_EXPORT]: 'Email Marketer (No Import/Export)',
  [RoleType.REPORT_VIEWER]: 'Report Viewer',
  [RoleType.GENERAL_USER_NO_EXPORT]: 'General User (No Export)',
  [RoleType.EMAIL_MARKETER_NO_LIST_SETTINGS]: 'Email Marketer (No List Settings)',
};

const STATUS_OPTIONS = Object.values(StatusType);
const USER_ROLE_OPTIONS = Object.values(RoleType);

const UserFiltersGroup: FC = () => {
  const { filters, updateFilter } = useTableFilters<UserFilters>();

  const handleChangeFilterOption = useCallback(
    <T extends string>(key: UserFilterKeys, value: T) =>
      updateFilter([
        {
          key,
          value,
        },
      ]),
    [updateFilter],
  );

  return (
    <div className="d-flex align-items-center justify-content-start gap-4">
      {'status' in filters && (
        <FilterTagDropdown
          title={`Status: ${HumanReadableStatusType[filters.status || StatusType.ACTIVE]}`}
          onClear={() =>
            updateFilter([
              {
                key: 'status',
                value: undefined,
              },
            ])
          }
        >
          {STATUS_OPTIONS.map((option, idx) => {
            const key = `${option}-${filters.status?.includes(option)}-${idx}`;
            return (
              <DropDownItemAsRadioGroup
                key={key}
                option={{ label: HumanReadableStatusType[option], value: option }}
                selected={filters.status}
                onChange={() => handleChangeFilterOption('status', option)}
              />
            );
          })}
        </FilterTagDropdown>
      )}

      {'role_id' in filters && (
        <FilterTagDropdown
          title={`Role: ${HumanReadableUserRoles[filters.role_id || RoleType.ALL]}`}
          onClear={() =>
            updateFilter([
              {
                key: 'role_id',
                value: undefined,
              },
            ])
          }
        >
          {USER_ROLE_OPTIONS.map((option, idx) => {
            const key = `${option}-${filters.status?.includes(option)}-${idx}`;
            return (
              <DropDownItemAsRadioGroup
                key={key}
                option={{ label: HumanReadableUserRoles[option], value: option }}
                selected={filters.role_id}
                onChange={() => handleChangeFilterOption('role_id', option)}
              />
            );
          })}
        </FilterTagDropdown>
      )}

      {'allowed_lists_ids' in filters && (
        <FilterTagDropdown
          title={`List Access: ${filters.allowed_lists_ids === 'all' ? 'Any' : `${filters.allowed_lists_ids?.split(',').length} ${filters.allowed_lists_ids && filters.allowed_lists_ids.split(',').length > 1 ? 'Lists' : 'List'}`}`}
          onClear={() =>
            updateFilter([
              {
                key: 'allowed_lists_ids',
                value: undefined,
              },
            ])
          }
          menuClassNames="w-25 pt-0"
        >
          <ListFilter
            onChange={(lists) =>
              handleChangeFilterOption(
                'allowed_lists_ids',
                lists?.map((element) => element.id).join(',') || 'all',
              )
            }
            selectedListIds={filters.allowed_lists_ids?.split(',') || []}
          />
        </FilterTagDropdown>
      )}
    </div>
  );
};

type ListFilterProps = {
  selectedListIds: string[];
  onChange: (lists: List[]) => void;
};

const labelFormatter = (l: List) => `[${l.id}] ${l.name}`;
const ListFilter: FC<ListFilterProps> = ({ selectedListIds, onChange }) => {
  const { isLoading, value, setSearch, options } = useSelectData({
    fetchCallback: (args) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useListsV2({
        ...args,
        type: 'sending',
        archive: ListShowArchivedType.EXCLUDE,
        sort: 'name',
        order: 'asc',
      }),
    ids: selectedListIds,
    labelFormatter,
  });

  const handleOptionClick = (option: (typeof options)[0], selectedBeforeToggle: boolean) => {
    const selectedOptions = selectedBeforeToggle
      ? value.filter((element) => element.value?.id !== option.value?.id)
      : [...value, option];

    onChange(
      selectedOptions.filter((e) => e.value !== undefined).map((element) => element.value as List),
    );
  };

  return (
    <DropDownItemAsSelect
      options={options}
      value={value}
      onSearchChange={setSearch}
      isLoading={isLoading}
      onOptionToggle={(option) =>
        handleOptionClick(option, !!value.find((e) => e.value?.id === option.value?.id))
      }
    />
  );
};
