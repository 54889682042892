import { FapiResponse, PasswordRecoveryResponse } from '@module/shared/models/FapiResponse';
import { ForgotPwdFormFields } from '@pages/auth/forgot-password/ForgotPasswordPage';
import axios from 'axios';

import { AuthModel, FirstTimeLoginModel, MFAModel } from './models';
import config from '../../../app-config';

export enum AUTH_URLS {
  LEGACY = 'legacy',
  REFRESH_TOKEN = 'refresh-token',
  REGISTER = 'register',
  MFA = 'mfa',
  FORGOT_PASSWORD = 'forgot_password',
  RESET_PASSWORD = 'reset_password',
  LOGIN_TOKEN = 'login_token',
}

const API_URL = `${config.fapiNewServiceUrl}${config.authUrl}/api/v1`;
const FAPI_URL = config.fapiUrl;

const LOGIN_URL = `${API_URL}/login/${AUTH_URLS.LEGACY}`;
const LOGIN_MFA_URL = `${API_URL}/login/${AUTH_URLS.MFA}`;
const REGISTER_URL = `${API_URL}/${AUTH_URLS.REGISTER}`;
const REFRESH_URL = `${API_URL}/login/${AUTH_URLS.REFRESH_TOKEN}`;
const FORGOT_PWD_URL = `${FAPI_URL}/api/auth/${AUTH_URLS.FORGOT_PASSWORD}`;
const UPDATE_PWD_URL = `${FAPI_URL}/api/auth/${AUTH_URLS.RESET_PASSWORD}`;
const VERIFY_NEW_ACC_EMAIL = `${FAPI_URL}/api/auth/${AUTH_URLS.LOGIN_TOKEN}`;

export function login(
  login: string,
  password: string,
  account_code?: string,
  remember_me?: boolean,
) {
  return axios.post<AuthModel | MFAModel>(LOGIN_URL, {
    username: login,
    password,
    account_code,
    remember_me,
  });
}

export function loginMFA(token: string, code: string, remember_me?: boolean) {
  return axios.post<AuthModel>(LOGIN_MFA_URL, {
    mfa_token: token,
    mfa_code: code,
    remember_me,
  });
}

export const refreshToken = (refreshToken: string, remember_me: boolean) =>
  axios.post<AuthModel>(REFRESH_URL, {
    refresh_token: refreshToken,
    remember_me,
  });

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

export const requestPassword = async (values: ForgotPwdFormFields) => {
  return await axios
    .post<FapiResponse<PasswordRecoveryResponse>>(FORGOT_PWD_URL, values)
    .then(({ data }) => data);
};

export const updatePassword = async (values) => {
  return await axios
    .post<FapiResponse<PasswordRecoveryResponse>>(UPDATE_PWD_URL, values)
    .then(({ data }) => data);
};

export const verifyNewAccEmail = async (token: string) => {
  return axios
    .put<FapiResponse<FirstTimeLoginModel>>(VERIFY_NEW_ACC_EMAIL, { token })
    .then(({ data }) => data);
};
