import { FC } from 'react';

import { Icon } from '../Icon/Icon';
import { Tooltip } from '../Tooltip';

type PasswordVisToggleProps = {
  fieldName: string;
  togglePasswordVisibility: (event: React.MouseEvent) => void;
  isPasswordVisible: (id: string) => boolean;
};

export const PasswordVisToggle: FC<PasswordVisToggleProps> = ({
  fieldName,
  togglePasswordVisibility,
  isPasswordVisible,
}) => {
  return (
    <Tooltip colorTheme="dark" description="Toggle password visibility" placement="top-start">
      <span
        tabIndex={0}
        className="d-inline-flex align-items-center justify-content-center"
        style={{
          pointerEvents: 'all',
        }}
        id={fieldName}
        onClick={togglePasswordVisibility}
        role="button"
      >
        <Icon name={isPasswordVisible(fieldName) ? 'eyeOff' : 'eye'} />
      </span>
    </Tooltip>
  );
};
