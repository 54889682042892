import { ComponentProps, FC, useCallback, useMemo } from 'react';
import { useExportListsToCsv } from '@module/list/hooks/list-queries';
import { ListFilters } from '@module/shared/api/list';
import { useTableFilters } from '@module/shared/hooks/useTableFilters';
import { RefreshTableQueryButton } from '@module/shared/ui/common/RefreshTableQueryButton';
import { TableColumnHidingFilter } from '@module/shared/ui/filter/TableColumnHidingFilter';
import { CommonTableActions } from '@module/shared/ui/table/CommonTableActions';
import { DropDownItemAsRadioGroup } from '@ui/DropdownItem/DropdownItemAsRadioGroup';
import { FiltersDropdown } from '@ui/FilterDropdown/Filters';
import { SearchInput } from '@ui/filters';
import { FilterTagDropdown } from '@ui/FiltersTagDropdown/FiltersTagDropdown';
import { TableControlsContainer } from '@ui/table/controls/TableControlsContainer';

export const ListTableFilters: FC = () => {
  const { filters, updateFilter } = useTableFilters<ListFilters>();

  const filtersDropdownOptions = useMemo<ComponentProps<typeof FiltersDropdown>['options']>(
    () => [
      {
        label: 'List Type',
        onClick: () => updateFilter([{ key: 'type', value: ListShowType.ALL }]),
        disabled: 'type' in filters,
      },
      {
        label: 'Archived Lists',
        onClick: () => updateFilter([{ key: 'archive', value: ListShowArchivedType.EXCLUDE }]),
        disabled: 'archive' in filters,
      },
      {
        label: 'Hash Type',
        onClick: () => updateFilter([{ key: 'hash_type', value: ListHashTypes.REGULAR }]),
        disabled: 'hash_type' in filters,
      },
    ],
    [updateFilter, filters],
  );

  const exportQuery = useExportListsToCsv();

  return (
    <div className="d-flex align-items-center justify-content-between w-100 gap-4">
      <ListFiltersGroup />
      <div className="d-flex align-items-center gap-4 justify-content-end">
        <FiltersDropdown options={filtersDropdownOptions} />
        <SearchInput />
        <TableControlsContainer>
          <RefreshTableQueryButton />
          <TableColumnHidingFilter />
          <CommonTableActions exportQuery={exportQuery} name={'List table export'} />
        </TableControlsContainer>
      </div>
    </div>
  );
};

type ListFilterKeys = 'type' | 'archive' | 'hash_type';

export enum ListShowType {
  ALL = 'all',
  SENDING = 'sending',
  SUPPRESSION = 'suppression',
}

export enum ListShowArchivedType {
  INCLUDE = 'include',
  EXCLUDE = '0',
  ONLY_ARCHIVED = '1',
}

export enum ListHashTypes {
  REGULAR = 'regular',
  MD5 = 'md5',
  SHA_256 = 'sha256',
  SHA_512 = 'sha512',
}

const HumanReadableListShowTypes = {
  [ListShowType.ALL]: 'All',
  [ListShowType.SENDING]: 'Sending List',
  [ListShowType.SUPPRESSION]: 'Suppression',
};

const HumanReadableShowArchivedTypes = {
  [ListShowArchivedType.INCLUDE]: 'Include Archived Lists',
  [ListShowArchivedType.EXCLUDE]: 'Exclude Archived Lists',
  [ListShowArchivedType.ONLY_ARCHIVED]: 'Only Archived Lists',
};

const HumanReadableListHashTypes = {
  [ListHashTypes.REGULAR]: 'Regular',
  [ListHashTypes.MD5]: 'MD5 Hash',
  [ListHashTypes.SHA_256]: 'SHA-256',
  [ListHashTypes.SHA_512]: 'SHA-512',
};

const LIST_SHOW_OPTIONS = Object.values(ListShowType);
const ARCHIVED_LISTS_OPTIONS = Object.values(ListShowArchivedType);
const HASH_TYPE_OPTIONS = Object.values(ListHashTypes);

const ListFiltersGroup: FC = () => {
  const { filters, updateFilter } = useTableFilters<ListFilters>();

  const handleChangeFilterOption = useCallback(
    <T extends string>(key: ListFilterKeys, value: T) =>
      updateFilter([
        {
          key,
          value,
        },
      ]),
    [updateFilter],
  );

  return (
    <div className="d-flex align-items-center justify-content-start gap-4">
      {'type' in filters && (
        <FilterTagDropdown
          title={`List Type: ${HumanReadableListShowTypes[filters.type || ListShowType.ALL]}`}
          onClear={() =>
            updateFilter([
              {
                key: 'type',
                value: undefined,
              },
            ])
          }
        >
          {LIST_SHOW_OPTIONS.map((option, idx) => {
            const key = `${option}-${filters.type?.includes(option)}-${idx}`;
            return (
              <DropDownItemAsRadioGroup
                key={key}
                option={{ label: HumanReadableListShowTypes[option], value: option }}
                selected={filters.type}
                onChange={() => handleChangeFilterOption('type', option)}
              />
            );
          })}
        </FilterTagDropdown>
      )}

      {'archive' in filters && (
        <FilterTagDropdown
          title={`Archived Lists: ${HumanReadableShowArchivedTypes[filters.archive || ListShowArchivedType.EXCLUDE]}`}
          onClear={() =>
            updateFilter([
              {
                key: 'archive',
                value: undefined,
              },
            ])
          }
        >
          {ARCHIVED_LISTS_OPTIONS.map((option, idx) => {
            const key = `${option}-${filters.archive?.includes(option)}-${idx}`;
            return (
              <DropDownItemAsRadioGroup
                key={key}
                option={{ label: HumanReadableShowArchivedTypes[option], value: option }}
                selected={filters.archive}
                onChange={() => handleChangeFilterOption('archive', option)}
              />
            );
          })}
        </FilterTagDropdown>
      )}

      {'hash_type' in filters && (
        <FilterTagDropdown
          title={`Hash Type: ${HumanReadableListHashTypes[filters.hash_type || ListHashTypes.REGULAR]}`}
          onClear={() =>
            updateFilter([
              {
                key: 'hash_type',
                value: undefined,
              },
            ])
          }
        >
          {HASH_TYPE_OPTIONS.map((option, idx) => {
            const key = `${option}-${filters.hash_type?.includes(option)}-${idx}`;
            return (
              <DropDownItemAsRadioGroup
                key={key}
                option={{ label: HumanReadableListHashTypes[option], value: option }}
                selected={filters.hash_type}
                onChange={() => handleChangeFilterOption('hash_type', option)}
              />
            );
          })}
        </FilterTagDropdown>
      )}
    </div>
  );
};
