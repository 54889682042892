import { FC, useEffect, useRef } from 'react';
import { OtpInput } from '@module/auth/components/OtpInput';
import { useLogin } from '@module/auth/queries';
import { useMfaToken, useMfaTokenActions, useQrCode } from '@module/auth/store/mfaTokenStore';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon/Icon';
import { toastError } from '@ui/Toasts';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';

const OTP_LENGTH = 6;

const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .length(OTP_LENGTH, `OTP Code must be a ${OTP_LENGTH} digit code`)
    .required('OTP is required'),
});

export const ConfirmMfaModal: FC<{ rememberMe: boolean }> = ({ rememberMe }) => {
  const mfaToken = useMfaToken();
  const qrCode = useQrCode();
  const { resetMfa } = useMfaTokenActions();
  const { mutateAsync: login, isPending } = useLogin();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const otpForm = useFormik<{ otp: string | null }>({
    initialValues: { otp: null },
    validateOnMount: true,
    validationSchema: otpSchema,
    onSubmit: async (values, { setFieldError }) => {
      if (mfaToken && values.otp) {
        await login(
          { mfaToken, mfaCode: values.otp, rememberMe },
          {
            onSuccess: () => {
              resetMfa();
            },
            onError: (err) => {
              if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                  setFieldError('otp', err.response?.data?.payload?.message);
                } else {
                  toastError({
                    title: '2-Factor Authentication',
                    text: (
                      <div className="d-flex flex-column">
                        <span>
                          {err.response?.data?.payload?.message ||
                            'An unexpected error occurred. Please try again'}
                        </span>
                      </div>
                    ),
                    autoClose: false,
                    withCloseButton: false,
                  });
                  resetMfa();
                }
              } else {
                toastError({
                  title: '2-Factor Authentication',
                  text: 'An unexpected error occurred. Please try again',
                });
              }
            },
          },
        );
      }
    },
  });

  useEffect(() => {
    const submitForm = otpForm.submitForm;

    if (otpSchema.isValidSync({ otp: otpForm.values.otp })) {
      submitForm();
    }
  }, [otpForm.submitForm, otpForm.values.otp]);

  const closeSelf = () => {
    resetMfa();
    otpForm.resetForm();
  };

  return (
    <Modal centered show={Boolean(mfaToken)} onHide={closeSelf} backdrop="static">
      <form onSubmit={otpForm.handleSubmit} noValidate>
        <Modal.Header className="border-0 p-8 d-flex flex-column align-items-start">
          <Icon
            name="backgroundCirclesPattern"
            className="position-absolute top-0 start-0 z-index-1"
            width={336}
            height={336}
          />
          <div className="d-flex mb-5">
            <div
              className="p-4 rounded-4 position-relative"
              style={{
                border: '1px solid #eaecf0',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              }}
            >
              <Icon name="lock" />
            </div>
          </div>
          <div className="d-flex flex-column gap-1 z-index-2">
            {qrCode ? (
              <>
                <Modal.Title className="fs-2">Set up two-factor authentication</Modal.Title>
                <p className="fs-6">
                  To authorise transactions, please scan this QR code with your Google Authenticator
                  App and enter the verification code below
                </p>
              </>
            ) : (
              <>
                <Modal.Title className="fs-2">2-Factor Authentication</Modal.Title>
                <p className="fs-6">Open your 2-Factor Authenticator app for valid code</p>
              </>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="p-8 pt-0 z-index-2">
          {qrCode && (
            <div className="text-center bg-gray-50 py-5 mb-6">
              <img alt="qr code" src={qrCode} style={{ width: '128px', aspectRatio: 1 }} />
            </div>
          )}
          <label className="fs-6 font-weight-bolder">Verification code</label>
          <OtpInput
            shouldAutoFocus
            otpLength={OTP_LENGTH}
            value={otpForm.values.otp}
            onChange={(otp) => {
              otpForm.setFieldError('otp', undefined);
              otpForm.setFieldValue('otp', otp, otp?.length === 6);
            }}
            error={otpForm.touched.otp ? otpForm.errors.otp : undefined}
          />
        </Modal.Body>
        <Modal.Footer className="border-0 p-8 pt-0">
          <Button
            variant="secondary"
            className="flex-grow-1 z-index-2"
            disabled={otpForm.isSubmitting}
            onClick={closeSelf}
          >
            Cancel
          </Button>
          {!otpForm.errors.otp?.includes('Please try again') ? (
            <Button
              type="submit"
              variant="info"
              className="flex-grow-1 z-index-2"
              disabled={otpForm.isSubmitting || !otpForm.isValid || !otpForm.dirty}
              ref={submitButtonRef}
            >
              {isPending ? (
                <span className="indicator-progress d-block">
                  Please wait
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">Confirm</span>
              )}
            </Button>
          ) : (
            <Button
              type="button"
              variant="info"
              className="flex-grow-1 z-index-2"
              disabled={!otpForm.dirty}
              onClick={() => otpForm.resetForm()}
            >
              <span className="indicator-label">Clear</span>
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};
