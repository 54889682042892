import { FC, forwardRef, useCallback, useRef, useState } from 'react';
import { FapiResponse } from '@module/shared/models/FapiResponse';
import { captureMessage } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { Icon } from '@ui/Icon/Icon';
import { toastError, toastWarn } from '@ui/Toasts';
import { createCsvFileFromBlob } from '@utils/common';
import { csvFileColumns } from '@utils/csvDownload';
import { AxiosError } from 'axios';
import { Col, Dropdown, DropdownToggleProps } from 'react-bootstrap';

import styles from '../../../../ui/table/controls/ControlWrapper.module.scss';
import { useAccountInfo } from '../../hooks/account-queries';
import { useTableFilters } from '../../hooks/useTableFilters';
import { TableDataState, useTableDataContext } from '../../store/useTableColumns';

type Props = (WithExport | WithoutExport) & (WithPagination | WithoutPagination);

type WithPagination = {
  useItemsPerPage: true;
  itemsPerPage?: number[];
};

type WithoutPagination = {
  useItemsPerPage?: false;
  itemsPerPage?: never;
};

export type ExportQueryParams = {
  format: 'json' | 'csv';
  columns: ReturnType<typeof csvFileColumns>;
  csv_title: string;
  time_zone?: string;
  limit: number;
};

type ExportQueryType = ReturnType<
  typeof useMutation<BlobPart | FapiResponse<{ url: string }>, Error, ExportQueryParams>
>;

const isUrlResponse = (
  resp: BlobPart | FapiResponse<{ url: string }>,
): resp is FapiResponse<{ url: string }> =>
  typeof resp === 'object' && 'payload' in resp && 'url' in resp.payload;

type WithExport = {
  name: string;
  useCsvExport?: true;
  exportQuery: ExportQueryType;
};

type WithoutExport = {
  name?: never;
  useCsvExport: false;
  exportQuery?: never;
};

const defaultItemsPerPage = [10, 25, 50, 100];

export const CommonTableActions: FC<Props> = ({
  useItemsPerPage = true,
  itemsPerPage,
  useCsvExport = true,
  name,
  exportQuery,
}) => {
  const memoItemsPerPage = useRef(useItemsPerPage ? itemsPerPage || defaultItemsPerPage : []);

  const [showItemsPerPage, setShowItemsPerPage] = useState(false);

  return (
    <Dropdown drop="down" autoClose="outside">
      <Dropdown.Toggle as="div" className={styles.controlWrapper}>
        <Icon name="dotsVertical" width={22} height={22} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border w-275px mt-4">
        {/* CSV Export */}
        {useCsvExport && <DownloadCsvDropdown name={name!} exportQuery={exportQuery!} />}

        {/* Items Per Page */}
        {useItemsPerPage && (
          <Dropdown.Item
            className="d-flex flex-row align-items-center"
            as="span"
            onClick={() => setShowItemsPerPage((s) => !s)}
          >
            <Col className="d-flex flex-row gap-2 px-2 py-1">
              <Icon name="arrowDown" />
              <span className="text-gray-700 fs-6">Items per page</span>
            </Col>
            <Col className="d-flex flex-row gap-2 px-2 py-1 justify-content-end cursor-pointer">
              <ItemsPerPageDropdown
                itemsPerPage={memoItemsPerPage.current}
                show={showItemsPerPage}
              />
            </Col>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const EXPORT_LIMIT = 40_000;
const DownloadCsvDropdown: FC<{ name: string; exportQuery: ExportQueryType }> = ({
  name,
  exportQuery,
}) => {
  const { data: account } = useAccountInfo();
  const columns = useTableDataContext((state) => state.columns);
  const { data } = useTableDataContext(
    (state: TableDataState<unknown, FapiResponse<unknown[]>>) => state.query,
  );

  const { mutate: exportToCsv, isPending } = exportQuery;

  const handleCsvExport = useCallback(() => {
    exportToCsv(
      {
        format: 'csv',
        columns: csvFileColumns(columns),
        csv_title: name,
        time_zone: account?.timezone || 'Asia/Jerusalem',
        limit: data?.metadata?.total || EXPORT_LIMIT,
      },
      {
        onSuccess: (resp) => {
          if (isUrlResponse(resp)) {
            window.open(resp.payload.url, '_blank');
          } else {
            return createCsvFileFromBlob(resp as BlobPart, name);
          }
        },
        onError: (error) =>
          toastError({
            title: 'Export CSV',
            text:
              error instanceof AxiosError
                ? error.response?.data?.payload?.message
                : 'Something went wrong. Please try again.',
          }),
        onSettled: (resp, err, params) => {
          if (resp && !isUrlResponse(resp) && params.limit > EXPORT_LIMIT) {
            captureMessage(
              `Warning: CSV export limit exceeded. Only ${EXPORT_LIMIT} rows are exported.`,
            );
            toastWarn({
              title: 'Export CSV',
              text: `Export limit exceeded. Only ${EXPORT_LIMIT} rows are exported. Please contact support for more information.`,
            });
          }
        },
      },
    );
  }, [account?.timezone, columns, data?.metadata?.total, exportToCsv, name]);

  return (
    <Dropdown.Item onClick={handleCsvExport} disabled={isPending}>
      <Col className="d-flex flex-row align-items-center gap-2 px-2 py-1">
        <Icon name="downloadCloud" />
        <span className="text-gray-700 fs-6">CSV</span>
      </Col>
    </Dropdown.Item>
  );
};

const ItemsPerPageDropdown: FC<{ itemsPerPage: number[]; show: boolean }> = ({
  itemsPerPage,
  show,
}) => {
  const { filters, updateFilter } = useTableFilters();

  const handleLimitChange = (limit: number) => {
    updateFilter([{ key: 'limit', value: limit }]);
  };

  return (
    <Dropdown drop="down-centered" show={show}>
      <Dropdown.Toggle as={ItemsPerPageTitle} />
      <Dropdown.Menu className="border w-200px mt-4">
        {itemsPerPage.map((limit) => (
          <Dropdown.Item key={limit} onClick={() => handleLimitChange(limit)}>
            <Col className="d-flex flex-row align-items-center gap-2 px-2 py-1 justify-content-between">
              <span className="text-gray-900 fw-semibold">{limit} items</span>
              {filters.limit === limit && <Icon name="check" stroke="primary" />}
            </Col>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ItemsPerPageTitle = forwardRef<HTMLSpanElement, DropdownToggleProps>(({ ...rest }, ref) => {
  const { filters } = useTableFilters();
  return (
    <span ref={ref} {...rest} className="text-gray-500 fs-6 justify-content-end">
      {filters.limit} items <Icon name="chevronDown" width={16} height={16} />
    </span>
  );
});
