import { ListField } from './models';

export enum Operator {
  IS = '=',
  IS_NOT = '!=',
  CONTAINS = 'LIKE',
  NOT_CONTAINS = 'NOT LIKE',
  BEGINS_WITH = 'LIKE_',
  ENDS_WITH = '_LIKE',
  EMPTY = 'empty',
  NOT_EMPTY = 'notempty',
  LOWER_THAN = '<',
  LOWER_THAN_OR_EQUALS_TO = '<=',
  BIGGER_THAN = '>',
  BIGGER_THAN_OR_EQUALS_TO = '>=',
  RANGE = 'range',
}

export enum ListFieldTypes {
  STRING = 'string',
  HUGE_STRING = 'huge_string',
  LONG_STRING = 'long_string',
  EMAIL = 'email',
  NUMERIC = 'numeric',
  DATE = 'date',
  // List fields has only "date" type, but when we send criteria, we have to declare "absolute" or "relative" type
  DATE_ABSOLUTE = 'date_absolute',
  DATE_RELATIVE = 'date_relative',
  SEGMENT = 'segment',
}

export enum SystemFieldTypes {
  ID = 'id',
  ENUM = 'enum',
  DATE = 'date',
  ENTITY = 'entity',
  NUMERIC = 'numeric',
}

export enum BehavioralTypes {
  BEHAVIORAL = 'behavioral',
}

export enum CombinedCondition {
  AND = 'and',
  OR = 'or',
}

export const CONDITION_OPTIONS = [
  {
    value: CombinedCondition.AND,
    label: 'And',
  },
  {
    value: CombinedCondition.OR,
    label: 'Or',
  },
];

export type ListSystemCriterion = {
  type: ListFieldTypes | SystemFieldTypes;
  group: string;
  operand: Array<string | number | { from: number | string; to: number | string }>;
  field_id: ListField['id'];
  operator: Operator;
  position: number;
  condition: CombinedCondition;
  field_name: ListField['name'];
  is_external_operand: boolean;
  case_sensitive?: boolean;
};

export type BehavioralCriterion = {
  type: BehavioralTypes;
  group: string;
  operand: {
    // TODO Will grow in feature + MA has same BehavioralCriterion but with different structure..
    mailing_ids?: string[];
  };
  operator: Operator.IS | Operator.IS_NOT;
  position: number;
  condition: CombinedCondition;
  field_name: (typeof BehavioralFieldNames)[keyof typeof BehavioralFieldNames];
};

export const BehavioralFieldNames = {
  SENT: 'sent',
  OPENED: 'opened',
  CLICKED: 'clicked',
  SOFT_BOUNCED: 'soft_bounce',
  EXTRA_1: 'extra1',
  EXTRA_2: 'extra2',
  EXTRA_3: 'extra3',
  EXTRA_4: 'extra4',
  EXTRA_5: 'extra5',
};

export const CriteriaRuleTypesWithOperators = {
  [ListFieldTypes.STRING]: [
    Operator.IS,
    Operator.IS_NOT,
    Operator.CONTAINS,
    Operator.NOT_CONTAINS,
    Operator.BEGINS_WITH,
    Operator.ENDS_WITH,
    Operator.EMPTY,
    Operator.NOT_EMPTY,
  ] as const,
  [ListFieldTypes.LONG_STRING]: [Operator.EMPTY, Operator.NOT_EMPTY] as const,
  [ListFieldTypes.HUGE_STRING]: [Operator.EMPTY, Operator.NOT_EMPTY] as const,
  [ListFieldTypes.EMAIL]: [
    Operator.IS,
    Operator.IS_NOT,
    Operator.CONTAINS,
    Operator.NOT_CONTAINS,
    Operator.BEGINS_WITH,
    Operator.ENDS_WITH,
    Operator.EMPTY,
    Operator.NOT_EMPTY,
  ] as const,
  // Since ListFieldTypes.NUMERIC === SystemFieldTypes.NUMERIC, so we don't need to redeclare it, maybe should be changed to switch
  [ListFieldTypes.NUMERIC]: [
    Operator.IS,
    Operator.IS_NOT,
    Operator.EMPTY,
    Operator.NOT_EMPTY,
    Operator.LOWER_THAN,
    Operator.LOWER_THAN_OR_EQUALS_TO,
    Operator.BIGGER_THAN,
    Operator.BIGGER_THAN_OR_EQUALS_TO,
    Operator.RANGE,
  ] as const,
  // Since ListFieldTypes.DATE === SystemFieldTypes.DATE, so we don't need to redeclare it, maybe should be changed to switch
  [ListFieldTypes.DATE]: [
    Operator.IS,
    Operator.IS_NOT,
    Operator.EMPTY,
    Operator.NOT_EMPTY,
    Operator.LOWER_THAN,
    Operator.LOWER_THAN_OR_EQUALS_TO,
    Operator.BIGGER_THAN,
    Operator.BIGGER_THAN_OR_EQUALS_TO,
  ] as const,
  [SystemFieldTypes.ID]: [Operator.IS] as const,
};

enum HumanFormat {
  EQUAL = 'equals',
  NOT_EQUAL = 'not equals',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'not contains',
  BEGINS_WITH = 'begins with',
  ENDS_WITH = 'ends with',
  EMPTY = 'empty',
  NOT_EMPTY = 'not empty',
  LOWER_THAN = 'lower than',
  LOWER_THAN_OR_EQUALS_TO = 'lower than or equals to',
  BIGGER_THAN = 'bigger than',
  BIGGER_THAN_OR_EQUALS_TO = 'bigger than or equals to',
  RANGE = 'range',

  // Date relative
  LESS_THAN = 'less than',
  LESS_THAN_OR_EQUALS_TO = 'less than or equals to',
  EQUAL_TO = 'equal to',
  MORE_THAN = 'more than',
  MORE_THAN_OR_EQUALS_TO = 'more than or equals to',

  // Date absolute
  BEFORE = 'before',
  AFTER = 'after',
  BEFORE_OR_ON = 'before or on',
  AFTER_OR_ON = 'after or on',
  ON = 'on',
  NOT_ON = 'not on',
  IS_EMPTY = 'is empty',
  IS_NOT_EMPTY = 'is not empty',

  // ACTION related operators
  OVERWRITE = 'Overwrite',
  APPEND = 'Append',
  PREPEND = 'Prepend',
  INCREMENT = 'Add',
  SUBTRACT = 'Subtract',

  NONE = 'Not selected',
}
export const OperatorToHumanFormat: { [key in Operator]: HumanFormat } = {
  [Operator.IS]: HumanFormat.EQUAL,
  [Operator.IS_NOT]: HumanFormat.NOT_EQUAL,
  [Operator.CONTAINS]: HumanFormat.CONTAINS,
  [Operator.NOT_CONTAINS]: HumanFormat.NOT_CONTAINS,
  [Operator.BEGINS_WITH]: HumanFormat.BEGINS_WITH,
  [Operator.ENDS_WITH]: HumanFormat.ENDS_WITH,
  [Operator.EMPTY]: HumanFormat.EMPTY,
  [Operator.NOT_EMPTY]: HumanFormat.NOT_EMPTY,
  [Operator.LOWER_THAN]: HumanFormat.LOWER_THAN,
  [Operator.LOWER_THAN_OR_EQUALS_TO]: HumanFormat.LOWER_THAN_OR_EQUALS_TO,
  [Operator.BIGGER_THAN]: HumanFormat.BIGGER_THAN,
  [Operator.BIGGER_THAN_OR_EQUALS_TO]: HumanFormat.BIGGER_THAN_OR_EQUALS_TO,
  [Operator.RANGE]: HumanFormat.RANGE,
};
