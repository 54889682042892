import { FC, LabelHTMLAttributes, PropsWithChildren, ReactElement, ReactNode } from 'react';
import clsx from 'clsx';

import { Icon } from '../Icon/Icon';
import { Input, InputProps } from '../Input';
import { Tooltip } from '../Tooltip';
import styles from './TextField.module.scss';

type PartialInputProps = Omit<InputProps, 'error' | 'name'>;

export interface TextFieldProps extends PartialInputProps {
  name: string;
  label?: string;
  error?: string | boolean;
  hint?: ReactNode;
  fullWidth?: boolean;
  renderInput?: (props: InputProps) => ReactElement;
}

export const TextField: FC<TextFieldProps> = ({
  label,
  hint,
  error = false,
  renderInput,
  ...inputProps
}) => {
  return (
    <div
      className={clsx('d-flex flex-column', {
        'w-100': inputProps.fullWidth,
      })}
    >
      {label && (
        <TextFieldLabel htmlFor={inputProps.id || inputProps.name}>
          <div className="d-flex text-gray-700 align-items-center">
            {label}
            {hint && (
              <Tooltip placement="top" colorTheme="dark" description={hint}>
                <span tabIndex={0} className="ms-2">
                  <Icon name="infoIcon" width={17} cursor="pointer" />
                </span>
              </Tooltip>
            )}
          </div>
        </TextFieldLabel>
      )}
      {typeof renderInput === 'function' ? (
        renderInput({ ...inputProps, error: Boolean(error) })
      ) : (
        <Input error={Boolean(error)} {...inputProps} />
      )}
      {!inputProps.disabled && error && typeof error === 'string' && (
        <TextFieldError>{error}</TextFieldError>
      )}
    </div>
  );
};

export const TextFieldLabel: FC<LabelHTMLAttributes<HTMLLabelElement>> = (props) => {
  return <label className="mb-2 fw-semibold fs-6" {...props} />;
};

export const TextFieldError: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <p className={clsx(styles.errorText, 'mt-2')}>{children}</p>
);
