import { FC } from 'react';

import { OutdatedCache } from './OutdatedCache';

type Props = {
  error: Error;
};

export const ErrorFallback: FC<Props> = ({ error }) => {
  const failedToFetchError = 'failed to fetch dynamically imported module';

  if (error.message.toLowerCase().includes(failedToFetchError)) {
    return <OutdatedCache />;
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100">
      <h1>Something went wrong, please contact support</h1>
      <pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
    </div>
  );
};
