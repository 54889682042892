import { useState } from 'react';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon/Icon';
import { Modal } from '@ui/Modal';
import { DateFieldFormats, TO_DATE_FNS_FORMAT } from '@utils/DateFieldFormats';
import { differenceInMonths, parse } from 'date-fns';

import config from '../../../../app-config';

const PRESENTATION_MODAL_KEY = 'presentation_modal_viewed';
const initialPresentationModalState =
  !localStorage.getItem(PRESENTATION_MODAL_KEY) ||
  localStorage.getItem(PRESENTATION_MODAL_KEY) === '0';

export const PresentationModal = () => {
  const pastLessThan3Months =
    differenceInMonths(
      new Date(),
      parse(config.releaseDate, TO_DATE_FNS_FORMAT[DateFieldFormats.MM_DD_YYYY_DASH], new Date()),
    ) <= 3;

  const [showPresentationModal, setShowPresentationModal] = useState(
    initialPresentationModalState && pastLessThan3Months,
  );

  const handleModalClose = () => {
    setShowPresentationModal(false);
    localStorage.setItem(PRESENTATION_MODAL_KEY, '1');
  };

  return (
    <Modal
      show={showPresentationModal}
      onClose={handleModalClose}
      backdrop="static"
      dialogClassName="w-350px"
    >
      <Modal.Header withCloseButton={false}>
        <Icon
          name="backgroundCirclesPattern"
          className="position-absolute top-0 start-0 z-index-1"
          width={350}
          height={350}
        />
        <Icon name={'confetti'} width={62} height={62} stroke="dark" />
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-1 position-relative z-index-2">
          <span className="text-gray-900 fs-4 fw-bold">Welcome to the New Ongage!</span>
          <span className="text-gray-600 fw-normal">
            We’ve upgraded to a fresh design to enhance your experience.
            <br />
            <br />
            💡 Bookmark the new address for easy access.
          </span>
          <Button variant="info" onClick={handleModalClose} className="mt-10">
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
