import { forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Dropdown, DropdownProps, DropdownToggleProps } from 'react-bootstrap';

import styles from './FiltersTag.module.scss';
import { Icon } from '../Icon/Icon';

type FilterTagDropdownProps = {
  title: string;
  onClear: () => void;
  menuClassNames?: string;
};
export const FilterTagDropdown = forwardRef<
  HTMLDivElement,
  PropsWithChildren<FilterTagDropdownProps>
>(({ title, onClear, children, menuClassNames }, ref) => {
  return (
    <Dropdown drop="down" as={TagToggle} autoClose={'outside'} ref={ref}>
      <Dropdown.Toggle split as={TagText} title={title} />
      <Icon
        name="xNoCircle"
        width={20}
        height={20}
        stroke="dark"
        hoverStroke="danger"
        onClick={onClear}
      />
      <Dropdown.Menu className={clsx('mt-4', styles.menu, menuClassNames)}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
});

const TagToggle = forwardRef<HTMLDivElement, PropsWithChildren<DropdownProps>>(
  ({ children, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(styles.toggleContainer, {
          [styles.active]: rest?.className?.includes('show'),
        })}
      >
        {children}
      </div>
    );
  },
);

const TagText = forwardRef<HTMLSpanElement, DropdownToggleProps>(({ title, ...rest }, ref) => (
  <span ref={ref} {...rest} className={styles.title}>
    {title}
  </span>
));
