import { useCallback, useState } from 'react';
import { useAuthTokensActions, usePreviousRefreshToken } from '@module/auth/store/authTokensStore';
import { useWrappedTo } from '@routing/to';
import { useQueryClient } from '@tanstack/react-query';
import { ROUTES } from '@utils/routes';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import config from '../../../../app-config';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const { logout, restorePreviousTokens } = useAuthTokensActions();
  const previousRefreshToken = usePreviousRefreshToken();
  const navigate = useNavigate();
  const to = useWrappedTo();

  return useCallback(() => {
    if (previousRefreshToken) {
      restorePreviousTokens();
      navigate(ROUTES.AUTH.LOGOUT, { state: { to: to(ROUTES.CAMPAIGNS.OVERVIEW) } });
    } else {
      logout();
      navigate(ROUTES.AUTH.LOGOUT, { state: { to: ROUTES.AUTH.LOGIN } });
    }
    queryClient.clear();
  }, [logout, navigate, previousRefreshToken, queryClient, restorePreviousTokens, to]);
};

export const Logout = () => {
  const { state } = useLocation();
  const [loggedOut, setLoggedOut] = useState(false);

  if (loggedOut) {
    return <Navigate to={state?.to || -1} />;
  }

  return (
    <>
      <iframe
        src={`${config.ongageApiUrl}/logout`}
        width={0}
        height={0}
        sandbox="allow-same-origin allow-scripts" // prevent redirecting
        onLoad={() => setLoggedOut(true)}
      />
      <LayoutSplashScreen />
    </>
  );
};
