import { DeactSettingsFormFields } from '@module/settings/components/list-management/components/DeactSettingsForm';
import axios from 'axios';

import config from '../../../../app-config';
import {
  AccountSettingsResponse,
  FapiResponse,
  UpdateProfileImageResponse,
} from '../models/FapiResponse';
import {
  AccountModel,
  DeactSettingsPesponseData,
  RegularUserUpdateAccountPayload,
} from '../models/models';

const API_URL = config.fapiUrl;
const API_URL_NEW_SERVICE = config.fapiNewServiceUrl;

const GET_ACCOUNT_URL = `${API_URL}/all/api/account`;
const UPDATE_ACCOUNT_IMAGE_URL = (acc_id: number) =>
  `${API_URL}/api/account/${acc_id}/upload_profile_image`;
const ACCOUNT_DEACT_SETTINGS_URL = (listId: number | 'all') =>
  `${API_URL}/${listId}/api/accounts/removal_settings`;

const GET_ACCOUNT_API_CALLS_COUNT_URL = (listId: number | 'all', acc_id: string) =>
  `${API_URL}/${listId}/api/account/${acc_id}/api_calls`;
const GET_ACCOUNT_CAMPAIGNS_COUNT_URL = (listId: number | 'all') =>
  `${API_URL}/${listId}/api/mailings/created_this_month`;
const GET_ACCOUNT_MESSAGES_COUNT_URL = (listId: number | 'all') =>
  `${API_URL}/${listId}/api/emails/created_this_month`;
const GET_ACCOUNT_MAIL_TESTER_COUNT_URL = (listId: number | 'all') =>
  `${API_URL}/${listId}/api/mailings/mail_tester_sent_today`;
const GET_ACCOUNT_MAX_FIELDS_LIST = `${API_URL}/api/lists/with_max_fields`;
const GET_ACCOUNT_MAX_ESP_CONNECTIONS = `${API_URL}/api/esp_connection?active=1&limit=1&sort=connections_count&order=desc&select=esp_id&virtual_fields=esp_name,connections_count&group=esp_id`;
const GET_ACCOUNT_SETTINGS = (accountId: number) =>
  `${API_URL_NEW_SERVICE}/email-validation/api/v1/account-setting/${accountId}`;

export const updateProfileImage = async (acc_id: number, image: File) => {
  const formData = new FormData();
  formData.append('account_logo', image);

  return await axios.post<FapiResponse<UpdateProfileImageResponse>>(
    UPDATE_ACCOUNT_IMAGE_URL(acc_id),
    formData,
  );
};

export const updateAccount = async (
  list_id: number | 'all',
  account_id: number,
  data: Partial<RegularUserUpdateAccountPayload>,
) => {
  return await axios.put(`${GET_ACCOUNT_URL}/${account_id}`, data).then(({ data }) => data);
};

export const getAccountInfo = async (account_id: number) => {
  return await axios
    .get<FapiResponse<AccountModel>>(`${GET_ACCOUNT_URL}/${account_id}`)
    .then(({ data }) => data);
};

export const getAccountDeactSettings = async (listId: number | 'all') => {
  return await axios
    .get<FapiResponse<DeactSettingsPesponseData>>(`${ACCOUNT_DEACT_SETTINGS_URL(listId)}`)
    .then(({ data }) => data);
};

export const updateAccountDeactSettings = async (
  listId: number | 'all',
  data: DeactSettingsFormFields,
) => {
  return await axios
    .put<FapiResponse<DeactSettingsPesponseData>>(`${ACCOUNT_DEACT_SETTINGS_URL(listId)}`, data)
    .then(({ data }) => data);
};

export const getAccountApiCallsCount = async (listId: number | 'all', account_id: string) => {
  return await axios
    .get<FapiResponse<number>>(GET_ACCOUNT_API_CALLS_COUNT_URL(listId, account_id))
    .then(({ data }) => data);
};

export const getAccountCampaingsCount = async (listId: number | 'all') => {
  return await axios
    .get<FapiResponse<number>>(GET_ACCOUNT_CAMPAIGNS_COUNT_URL(listId))
    .then(({ data }) => data);
};

export const getAccountMessagesCount = async (listId: number | 'all') => {
  return await axios
    .get<FapiResponse<number>>(GET_ACCOUNT_MESSAGES_COUNT_URL(listId))
    .then(({ data }) => data);
};

export const getAccountMailTesterCount = async (listId: number | 'all') => {
  return await axios
    .get<FapiResponse<number>>(GET_ACCOUNT_MAIL_TESTER_COUNT_URL(listId))
    .then(({ data }) => data);
};

export type MaxFieldsListResponse = {
  count: number;
  list_id: string;
  list_name: string;
};

export const getMaxListFields = async () => {
  return await axios
    .get<FapiResponse<MaxFieldsListResponse>>(GET_ACCOUNT_MAX_FIELDS_LIST)
    .then(({ data }) => data);
};

export type MaxEspConnResponse = Record<
  number,
  { esp_id: string; esp_name: string; connections_count: number }
>;

export const getMaxEspConnections = async () => {
  return await axios
    .get<FapiResponse<MaxEspConnResponse>>(GET_ACCOUNT_MAX_ESP_CONNECTIONS)
    .then(({ data }) => data);
};

export const getAccountSettings = async (accoundId: number) => {
  return await axios
    .get<FapiResponse<AccountSettingsResponse>>(GET_ACCOUNT_SETTINGS(accoundId))
    .then(({ data }) => data);
};
