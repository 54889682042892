export interface Config {
  publicUrl: string;
  fapiUrl: string;
  // # this url should be used for any new services (like auth / ma / analytics)
  fapiNewServiceUrl: string;
  uploadSslFAQUrl: string;
  ongageApiUrl: string;
  maUrl: string;
  analyticsUrl: string;
  aiUrl: string;
  authUrl: string;
  privacyPolicyUrl: string;
  termsOfServiceUrl: string;
  registrationUrl: string;
  authAppStoreUrl: string;
  authGooglePlayUrl: string;
  postHog: {
    enabled: boolean;
    host: string;
    key: string;
  };
  sentry: {
    dsn: string;
  };
  env: 'development' | 'production';
  releaseDate: string;
  iframeUrl: string;
}

export const config: Readonly<Config> = {
  publicUrl: import.meta.env.PUBLIC_URL || '/',
  fapiUrl: import.meta.env.VITE_APP_FAPI_API_URL,
  iframeUrl: import.meta.env.VITE_APP_IFRAME_URL || '',
  // # this url should be used for any new services (like auth / ma / analytics)
  fapiNewServiceUrl: import.meta.env.VITE_APP_FAPI_SERVICES_API_URL,
  uploadSslFAQUrl: import.meta.env.VITE_APP_UPLOAD_SSL_FAQ_URL,
  ongageApiUrl: import.meta.env.VITE_APP_ONGAGE_API_URL || '',
  maUrl: import.meta.env.VITE_APP_MA_URL || '',
  analyticsUrl: import.meta.env.VITE_APP_ANALYTICS_URL || '',
  aiUrl: import.meta.env.VITE_APP_AI_URL || '',
  authUrl: import.meta.env.VITE_APP_AUTH_URL || '',
  privacyPolicyUrl: import.meta.env.VITE_APP_PRIVACY_POLICY_URL || '',
  termsOfServiceUrl: import.meta.env.VITE_APP_TERMS_OF_SERVICE_URL || '',
  registrationUrl: import.meta.env.VITE_APP_REGISTRATION_URL || '',
  authAppStoreUrl: import.meta.env.VITE_APP_AUTHENTICATOR_APP_STORE_URL || '',
  authGooglePlayUrl: import.meta.env.VITE_APP_AUTHENTICATOR_GOOGLE_PLAY_URL || '',
  postHog: {
    enabled: import.meta.env.VITE_APP_PUBLIC_POSTHOG_ENABLED === '1' || false,
    host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST || '',
    key: import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY || '',
  },
  sentry: {
    dsn: import.meta.env.VITE_APP_SENTRY_DSN_URL || '',
  },
  env: import.meta.env.MODE as 'development' | 'production',
  releaseDate: import.meta.env.VITE_APP_RELEASE_DATE,
} as const;

export default config;
